import dxpDigitalDataAssignment from '../../../../../common/utilities/dxpDigitalDataAssignment';
import {
  DxpDataLayerEvent,
  DxpDataLayerEventNames,
  DxpDataLayerType,
} from '../../../../../common/utilities/customDatalayerInterface';
import dxpProducts from '../../../../../common/constants/dxpProducts';
import { CommonStepProps, formatDataLayerDeal, getCheckoutSteps } from '../utils/dxpDataLayerHandler';
import { GetDeal_deal } from '../graphql/queries/__generated__/GetDeal';

const useCallDxpDigitalAssignmentEntryEvent = () => {
  const callDxpDigitalAssignmentEntryEvent = (
    deal: GetDeal_deal,
    steps: CommonStepProps[],
    activeStep: number,
    extraProps?: Partial<DxpDataLayerType>,
  ) => {
    try {
      dxpDigitalDataAssignment({
        event: DxpDataLayerEvent.CONFIGURATOR,
        eventName: DxpDataLayerEventNames.CONFIGURATOR_ENTRY,
        pageTitle: document.title,
        DXPProduct: dxpProducts.checkout,
        ...formatDataLayerDeal(deal),
        ...getCheckoutSteps(steps, activeStep),
        ...extraProps,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('DataLayer error', err);
    }
  };

  return { callDxpDigitalAssignmentEntryEvent };
};

export default useCallDxpDigitalAssignmentEntryEvent;
