import { gql } from '@apollo/client/core';

const exterior = {
  EXTERIOR: gql`
    fragment Exterior on Exterior {
      code @include(if: $dataLayer)
      categoryCode @include(if: $dataLayer)
      shortname
      attributes {
        oemcode
      }
      price {
        ...Price
      }
    }
  `,
};

export default exterior;
