import React, { FunctionComponent, useEffect } from 'react';
import { Box } from '@material-ui/core';
import dealerSelectorStyles from './dealerSelectorStyles';
import DealersListContainer from './dealerListContainer/DealersListContainer';
import DealersMap from './dealersMap/DealersMap';
import { Routes } from '../../../../../common/enum/Routes';
import { useHistory } from 'react-router';
import MinimalLoader from '../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../../../../../common/components/presentation/Error/ThemedErrorMessage';
import useDealerSelectorDataPrep from '../../hooks/useDealerSelectorDataPrep';
import useSortDealers from '../../hooks/useSortDealers';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import ContinueButton from '../../../../common/components/continueButton/ContinueButton';
import errorHandler from '../../../../../common/service/errorHandler';
import usePlatformConfig from '../../../../../common/react/usePlatformConfig';
import { StockType } from '../../../../../__generated__/globalTypes';
import useJourneyStepsContext from '../../../../checkout4/hooks/useJourneyStepsContext';
import dxpDigitalDataAssignment from '../../../../../../../common/utilities/dxpDigitalDataAssignment';
import { DxpDataLayerEvent, DxpDataLayerEventNames } from '../../../../../../../common/utilities/customDatalayerInterface';
import dxpProducts from '../../../../../../../common/constants/dxpProducts';
import { formatDataLayerDeal, getSaveEnquiryFormNextSteps } from '../../../../common/utils/dxpDataLayerHandler';
import { JourneyStepsRoutes } from '../../../../checkout4/enum/JourneyStepsRoutes';

const DealerSelector: FunctionComponent = () => {
  const styles = dealerSelectorStyles();
  const {
    platformConfig: {
      checkout,
      platformFeatures: { isCheckout4Enabled },
      dxpDataLayer,
    },
  } = usePlatformConfig();

  const history = useHistory();
  const {
    dealers,
    defaultDealer,
    setSelectedDealerId,
    selectedDealerId,
    loading,
    error,
    selectDealerMutation,
    disabled,
  } = useDealerSelectorDataPrep();
  const sortedDealers = useSortDealers(dealers);
  const { deal, refetch, stockType } = useCheckoutContext();
  const { handleNext } = useJourneyStepsContext();

  const dealerIdSavedOnDeal = deal?.dealer?.externalId;
  const { disableDealerSelect } = checkout || {};
  const disableDealerSelection = disableDealerSelect && stockType === StockType.dealer;
  const isDistributionStock = deal?.attributes?.isDistributionStock;

  useEffect(() => {
    if (isDistributionStock) {
      clickContinueCTA();
    }
  }, []);

  const selectDealer = (id: string) => {
    if (!selectedDealerId || selectedDealerId !== id) {
      setSelectedDealerId(id);
      const dealerLabel = dealers.find(dealer => dealer.externalId === id)?.label
      if (dxpDataLayer && deal?.dealer?.label || dealerLabel) {
        dxpDigitalDataAssignment({
          event: DxpDataLayerEvent.CONFIGURATOR,
          eventName: DxpDataLayerEventNames.CNF_DEALERS_CLICKED,
          pageTitle: document.title,
          DXPProduct: dxpProducts.checkout,
          dealerPreference: deal?.dealer?.label || dealerLabel || '',
          ...formatDataLayerDeal(deal),
          nextSteps: getSaveEnquiryFormNextSteps(),
          stepName: JourneyStepsRoutes.DEALER.replace('/', '')
        })
      }
    }
    if (selectedDealerId && selectedDealerId === id) {
      setSelectedDealerId(null);
    }
  };

  const clickContinueCTA = async () => {
    try {
      if (dealerIdSavedOnDeal !== selectedDealerId) {
        await selectDealerMutation();
      }
      if (isCheckout4Enabled) {
        handleNext();
        refetch && refetch();
      } else {
        history.push({ ...history.location, pathname: Routes.REQUEST_QUOTE });
      }
    } catch (error) {
      errorHandler.handleError(error);
    }
  };

  if (loading) {
    return <MinimalLoader />;
  }

  if (error) {
    return <ThemedErrorMessage fullPage={true} error={error} />;
  }

  return (
    <>
      <Box className={styles.container} data-test="container:checkout:dealer">
        <DealersListContainer
          dealers={sortedDealers}
          selectedDealerId={selectedDealerId}
          selectDealerId={selectDealer}
          disableDealerSelection={disableDealerSelection}
        />
        <DealersMap
          defaultDealer={defaultDealer}
          dealers={sortedDealers}
          selectedDealerId={selectedDealerId}
          selectDealerId={selectDealer}
          disableDealerSelection={disableDealerSelection}
        />
      </Box>
      <Box className={styles.actionButtonsContainer}>
        <ContinueButton onClick={clickContinueCTA} testId={'dealer:continue:button'} disabled={disabled}>
          <Translation id={'trans__checkout__dealers_page__continue_CTA'} />
        </ContinueButton>
      </Box>
    </>
  );
};

export default DealerSelector;
