import { gql } from '@apollo/client/core';

const engine = {
  ENGINE: gql`
    fragment Engine on Engine {
      code
      attributes {
        shortname
        motor
        shortdescription
        longdescription
        fuelType
        fuelLabel
        powertrain
        capacity
        fueleconomy_combinedwltp_co2_tel
        transmissionLabel
        maximumpower @include(if: $dataLayer)
        enginepower @include(if: $dataLayer)
        transmissionType @include(if: $dataLayer)
        maximumtorque @include(if: $dataLayer)
      }
    }
  `,
};

export default engine;
