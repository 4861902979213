import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type GenericInputClasses =
  | 'root'
  | 'fieldWrapper'
  | 'input'
  | 'errorText'
  | 'notchedOutline'
  | 'focused'
  | 'inputError'
  | 'helperTextError'
  | 'dateCalendar'
  | 'label'
  | 'disabled'
  | 'phonePrefix'
  | 'noOptions'
  | 'inputNoLabel'
  | 'tooltipIcon'
  | 'listbox'
  | 'option';

const genericInputStyles = makeStyles<ICheckoutTheme, GenericInputClasses>(
  ({ typography, transitions, palette, spacing, breakpoints }) => ({
    root: {
      background: palette.common.white,
    },
    fieldWrapper: {
      position: 'relative',
      width: '100%',
    },
    input: {
      width: '100%',
      transition: transitions.create(['border-color', 'box-shadow']),
      '& input': {
        width: '100%',
        padding: 0,
        height: 'fit-content',
      },
    },
    errorText: {
      fontSize: typography.fontXS,
    },
    notchedOutline: {
      borderRadius: 3,
      borderColor: palette.text.secondary,
      borderWidth: 1,
    },
    focused: {
      borderColor: palette.primary.main,
      boxShadow: 'inset 0 0 10px 1px rgba(150,184,212,0.5)',
    },
    inputError: {
      borderColor: palette.error.main,
    },
    dateCalendar: {
      '& span': {
        color: palette.primary.main,
      },

      [breakpoints.down('sm')]: {
        paddingRight: '10px',
      },
    },
    helperTextError: {
      margin: spacing(1),
      color: palette.error.main,
      position: 'absolute',
      top: 40,
    },

    label: {
      fontFamily: typography.fontFamily2,
      color: palette.text.primary,
      marginBottom: 6,
      lineHeight: '20px',
    },
    phonePrefix: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '52px',
      width: '48px',
      backgroundColor: palette.action.disabledBackground,
      fontSize: typography.fontMD,
      marginLeft: '-11px',
    },
    noOptions: {
      [breakpoints.down('md')]: {
        fontSize: typography.fontSM,
      },
      [breakpoints.down('sm')]: {
        fontSize: typography.fontXS,
      },
    },
    inputNoLabel: {},
    tooltipIcon: {
      fontSize: typography.fontLG,
    },
    disabled: {
      backgroundColor: palette.background.alternate,
    },
    listbox: {},
    option: {},
  }),
  { name: 'GqlCheckout_GenericInput' },
);

export default genericInputStyles;
