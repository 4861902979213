import React, { FunctionComponent, useEffect } from 'react';
import CheckoutLiteRouter from './CheckoutLiteRouter';
import usePlatformConfig from '../react/usePlatformConfig';
import Checkout4LiteRouter from '../../gql/checkout4/components/Checkout4LiteRouter';
import MiniMyAccountComponent from './myAccountDxp/MiniMyAccountComponent';
import dxpDataLayerInitializationHandler from '../../../../common/utilities/dxpDataLayerInitializationHandler';
import dataLayerInitializationHandler from '../../../../common/utilities/dataLayerInitializationHandler';
import useWidgetContext from '../react/useWidgetContext';
import { toggleDataLayer } from '../../../../common/service/toggleDataLayer';
import { dataLayerPriceDisplay } from '../../../../common/service/dataLayerPriceDisplay';

const WidgetContent: FunctionComponent = () => {
  const {
    platformConfig: {
      platformFeatures: { isCheckout4Enabled, dataLayer },
      myAccount,
      dxpDataLayer,
      priceDisplay,
    },
  } = usePlatformConfig();
  const { configuration } = useWidgetContext();

  dxpDataLayerInitializationHandler(dxpDataLayer);

  useEffect(() => {
    if (dataLayer) {
      toggleDataLayer.set(configuration.dimensions, dataLayer);
      dataLayerInitializationHandler(configuration.dimensions);
      dataLayerPriceDisplay.set(configuration.dimensions, priceDisplay);
    }
  }, [dataLayer, configuration]);

  return isCheckout4Enabled ? (
    <>
      {myAccount?.miniMyAccountConfig && <MiniMyAccountComponent />}
      <Checkout4LiteRouter />
    </>
  ) : (
    <CheckoutLiteRouter />
  );
};

export default WidgetContent;
