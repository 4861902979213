import { gql } from '@apollo/client/core';
import dealData from '../../../../common/graphql/fragments/deal/dealData';

const enquiryForm = {
  SAVE_FORM: gql`
    ${dealData.DEAL_DATA}
    mutation SaveEnquiryForm(
      $dealId: String!
      $state: String
      $captchaToken: String!
      $data: EnquiryFormData
      $dataLayer: Boolean!
    ) {
      saveEnquiryForm(dealId: $dealId, state: $state, captchaToken: $captchaToken, data: $data) {
        ...DealData
      }
    }
  `,
};

export default enquiryForm;
