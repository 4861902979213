import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import useWidgetContext from '../react/useWidgetContext';
import { WidgetEvents } from '../event/WidgetEvents';
import DeferredPaymentPage from '../../payment';
import DeferredGqlPaymentPage from '../../gql/checkout3/payment';
import DeferredReserveOnlineConfirmationPage from '../../confirmation/ReserveOnline';
import DeferredContinueInDealershipConfirmationPage from '../../confirmation/ContinueInDealer';
import GqlDeferredContinueInDealershipConfirmation from '../../gql/checkout3/confirmation/ContinueInDealer';
import { Routes } from '../enum/Routes';
import DeferredContinueInDealership from '../../continueInDealership';
import DeferredReserveOnline from '../../reserveOnline';
import { createBrowserHistory } from 'history';
import useDealErrorHandler from '../react/useDealErrorHandler';
import useAppContext from '../react/useAppContext';
import DealUnavailablePage from './presentation/ExceptionPages/DealUnavailablePage';
import AccessDeniedPage from './presentation/ExceptionPages/AccessDeniedPage';
import NotFoundPage from './presentation/ExceptionPages/NotFoundPage';
import { Redirect } from 'react-router';
import errorMessageHoc, { IErrorMessageHocProps } from './presentation/Error/errorMessageHoc';
import useRemoveElementOnPageChange from '../react/useRemoveElementOnPageChange';
import useGqlRestSwitch from '../../gql/common/hooks/useGqlRestSwitch';
import DeferredDealer from '../../gql/checkout3/dealer';
import usePlatformConfig from '../react/usePlatformConfig';
import NavigationStepper from '../../gql/checkout3/common/components/presentation/navigationStepper/NavigationStepper';
import GqlDeferredContinueInDealership from '../../gql/checkout3/continueInDealership';
import GqlDeferredReserveOnlineConfirmation from '../../gql/checkout3/confirmation/ReserveOnline';
import { Box } from '@material-ui/core';
import checkoutLiteMainRoutesContainerStyles from './checkoutLiteMainRoutesContainerStyles';
import GqlDeferredReserveOnline from '../../gql/checkout3/reserveOnline';
import DeferredExtras from '../../gql/checkout3/extras';
import useRedirect from '../hooks/useRedirect';
import useAzureMonitor from '../hooks/useAzureMonitor';
import SeoHelmet from '../../gql/common/seo/SeoHelmet';

const CheckoutLiteRouter: FunctionComponent<IErrorMessageHocProps> = ({ componentHandleError }) => {
  const styles = checkoutLiteMainRoutesContainerStyles();
  const { eventContainer, configuration } = useWidgetContext();
  const { dealError } = useAppContext();
  const { isGqlApi } = useGqlRestSwitch();
  const redirectProps = useRedirect();
  useAzureMonitor();

  const {
    platformConfig: {
      platformFeatures: { isDealerEnabled, showNavigationBar, isExtrasPageEnabled },
    },
  } = usePlatformConfig();
  const [history] = useState(createBrowserHistory({ basename: configuration.basePath }));

  useRemoveElementOnPageChange({ elementId: 'fcTooltip' });
  const handleDealError = useDealErrorHandler(history);

  useEffect(() => {
    eventContainer.emit(WidgetEvents.INITIALIZED);

    if (dealError && !isGqlApi) {
      componentHandleError(handleDealError(dealError));
    }
  }, []);

  return (
    <Router history={history}>
      <SeoHelmet />
      <Switch>
        {showNavigationBar && (
          <Route
            exact={true}
            path={[
              Routes.EXTRAS,
              Routes.DEALER,
              Routes.REQUEST_QUOTE,
              Routes.CONFIRMATION_REQUEST_QUOTE,
              ...(isGqlApi ? [Routes.RESERVE_ONLINE, Routes.PAYMENT, Routes.CONFIRMATION_RESERVE_ONLINE] : []),
            ]}
            component={NavigationStepper}
          />
        )}
      </Switch>
      <Box className={styles.root}>
        <Switch>
          {redirectProps && <Redirect {...redirectProps} />}
          {isExtrasPageEnabled && <Route exact={true} path={Routes.EXTRAS} component={DeferredExtras} />}
          {isDealerEnabled && <Route exact={true} path={Routes.DEALER} component={DeferredDealer} />}
          <Route
            exact={true}
            path={Routes.REQUEST_QUOTE}
            component={isGqlApi ? GqlDeferredContinueInDealership : DeferredContinueInDealership}
          />
          <Route
            exact={true}
            path={Routes.RESERVE_ONLINE}
            component={isGqlApi ? GqlDeferredReserveOnline : DeferredReserveOnline}
          />
          <Route
            exact={true}
            path={Routes.PAYMENT}
            component={isGqlApi ? DeferredGqlPaymentPage : DeferredPaymentPage}
          />
          <Route
            exact={true}
            path={Routes.CONFIRMATION_REQUEST_QUOTE}
            component={
              isGqlApi ? GqlDeferredContinueInDealershipConfirmation : DeferredContinueInDealershipConfirmationPage
            }
          />
          <Route
            exact={true}
            path={Routes.CONFIRMATION_RESERVE_ONLINE}
            component={isGqlApi ? GqlDeferredReserveOnlineConfirmation : DeferredReserveOnlineConfirmationPage}
          />
          <Route exact={true} path={Routes.DEAL_UNAVAILABLE} component={DealUnavailablePage} />
          <Route exact={true} path={Routes.ACCESS_DENIED} component={AccessDeniedPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Box>
    </Router>
  );
};

export default errorMessageHoc(CheckoutLiteRouter);
