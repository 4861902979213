import React, { FC, useMemo, useCallback } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import genericInputStyles from '../genericInput/GenericInputStyles';
import { TextareaAutosize, Typography, InputLabel, Box } from '@material-ui/core';
import textareaAutosizeInputStyles from './TextareaAutosizeInputStyles';

const TextareaAutosizeInput: FC<ISchemaFieldProps> = ({ formData }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    options: { inputLabel },
    placeholder,
  } = formData;
  const styles = textareaAutosizeInputStyles();
  const genericInputStyle = genericInputStyles();
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const handleTextareaChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onChange(e.target.value as string);
    },
    [value],
  );

  return (
    <Box className={styles.container}>
      {inputLabel && (
        <Box display="flex">
          <Typography component="span" variant="subtitle1">
            <InputLabel
              htmlFor={id}
              required={required}
              className={genericInputStyle.label}
              data-test={`textareaAutosize_input:label:${id}`}
            >
              {label}
            </InputLabel>
          </Typography>
        </Box>
      )}
      <TextareaAutosize
        data-test={`textareaAutosize_input:${id}`}
        id={id}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={handleTextareaChange}
        className={styles.root}
      />
      {currentError && (
        <Typography
          component="span"
          className={genericInputStyle.errorText}
          data-test={`textareaAutosize_input:error:${id}`}
        >
          {currentError}
        </Typography>
      )}
    </Box>
  );
};

export default TextareaAutosizeInput;
