import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type TextareaAutosizeInputClasses = 'root' | 'container';

const textareaAutosizeInputStyles = makeStyles<ICheckoutTheme, TextareaAutosizeInputClasses>(
  ({ palette, typography }) => ({
    container: {
      whiteSpace: 'pre-wrap',
    },
    root: {
      background: palette.common.white,
      width: '100%',
      padding: '8px',
      outline: 'none',
      resize: 'none',
      border: `1px solid ${palette.primary.light}`,
      lineHeight: 2,
      minHeight: 40,
      borderRadius: 0,

      '&:hover': {
        borderColor: palette.primary.main,
      },

      '&:focus': {
        borderColor: palette.primary.main,
      },

      '&::placeholder': {
        textTransform: 'initial',
        lineHeight: 2,
        fontSize: typography.caption.fontSize,
        opacity: 0.5,
      },
    },
  }),
  { name: 'GqlCheckout_TextareaAutosizeInput' },
);

export default textareaAutosizeInputStyles;
