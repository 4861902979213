import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type MercadoPagoContainerClasses = 'container';

const mercadoPagoContainerStyles = makeStyles<ICheckoutTheme, MercadoPagoContainerClasses>(
  () => ({
    container: {
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'Gql_MercadoPagoContainer' },
);

export default mercadoPagoContainerStyles;
