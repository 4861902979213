import { gql } from '@apollo/client/core';
import dealData from '../fragments/deal/dealData';

const deal = {
  GET_DEAL: gql`
    ${dealData.DEAL_DATA}
    query GetDeal($token: String!, $dataLayer: Boolean!) {
      deal(token: $token) {
        ...DealData
      }
    }
  `,
};

export default deal;
