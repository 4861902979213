import { ApolloClient, createHttpLink, from, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import cache from './cache';
import IDimensions from '../../../common/model/IDimensions';
import useToken from '../../../gql/common/hooks/useToken';

export type CustomGraphqlError = {
  code?: string;
  message?: string;
};

// TODO: need to removed some of the data here once re-skin layout is done.
const typeDefs = gql`
  type FeatureSection {
    id: String
    value: Boolean
  }

  type SelectedFeatureInput {
    selected: [String]
    added: [String]
    removed: [String]
  }

  extend type CatalogResult {
    financeFormInput: FinanceFormInput
    selectedCarNextFilters: Filters
  }

  extend type CustomizedCarConfiguration {
    financeFormInput: FinanceFormInput
    selectedActiveSectionName: String
    selectedCurrency: String
    selectedSections: [FeatureSection]
    selectedFeaturesId: [String]
    selectedFeaturesInput: SelectedFeatureInput
  }

  extend type TrimLevelVariants {
    financeFormInput: FinanceFormInput
    marketingCategories: [String]
  }
  extend type EngineResult {
    financeFormInput: FinanceFormInput
  }
  extend type BodyStyleResult {
    financeFormInput: FinanceFormInput
  }
  extend type ModelResult {
    financeFormInput: FinanceFormInput
  }
  extend type SpecPackResult {
    financeFormInput: FinanceFormInput
  }
`;

const sessionErrorCodes: Array<string | undefined> = ['SESSION_NOT_FOUND', 'SESSION_INVALID', 'SESSION_EXPIRED'];

const exceptOperationNames = (operationName?: string) => {
  if (!operationName) {
    return false;
  }

  return ['GetTranslations', 'SelectDealer', 'GetDeal', 'GetDealers'].indexOf(operationName) === -1;
};

const httpLink = (dimensions: IDimensions) =>
  createHttpLink({
    uri: process.env.GRAPHQL_SERVER_URL,
    headers: {
      'X-Brand': dimensions.brand,
      'X-Language': dimensions.language,
      'X-Region': dimensions.region,
      'X-Country': dimensions.country,
    },
  });

const generateApolloClient = (dimensions: IDimensions) => {
  const { getCurrentToken, removeCurrentToken } = useToken();
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach(graphQLError => {
          if (sessionErrorCodes.includes((graphQLError as CustomGraphqlError).code)) {
            removeCurrentToken();
          }
        });
      } else if (sessionErrorCodes.includes((graphQLErrors as CustomGraphqlError).code)) {
        removeCurrentToken();
      }
    }
  });
  const authLink = setContext((request, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(exceptOperationNames(request.operationName) && {
          Authorization: getCurrentToken() || '',
        }),
      },
    };
  });

  return new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink(dimensions))]),
    cache,
    typeDefs,
  });
};
export default generateApolloClient;
