import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { IOption, ISchemaFieldProps } from '../../model/IJsonSchema';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import GenericInput from '../genericInput/GenericInput';
import genericInputStyles from '../genericInput/GenericInputStyles';

const AutocompleteInput: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const { label, required, id, value, options, onChange, placeholder, schema } = formData;
  const styles = genericInputStyles();
  const [dropdownOpened, setDropdownOpen] = useState(false);
  const defaultValue = options.enumOptions.find(option => option.value === value);
  const filterOptions = createFilterOptions<IOption>({
    matchFrom: 'any',
    limit: 50,
  });

  function handleOnChange(option: IOption) {
    onChange(option?.value);
  }

  return (
    <Autocomplete
      open={dropdownOpened}
      filterOptions={filterOptions}
      noOptionsText={options.invalidOption}
      onChange={(_: ChangeEvent<{ value?: string }>, option: IOption) => handleOnChange(option)}
      onOpen={() => !schema?.readOnly && setDropdownOpen(true)}
      onClose={() => setDropdownOpen(false)}
      classes={{
        input: styles.input,
        noOptions: styles.noOptions,
        listbox: styles.listbox,
        option: styles.option,
      }}
      id={id}
      options={options.enumOptions}
      getOptionLabel={option => option.label}
      disableClearable={schema?.readOnly}
      defaultValue={defaultValue}
      data-test={`autocomplete_input:${id}`}
      renderInput={params => {
        const inputProps = {
          label,
          required,
          id,
          value,
          params,
          placeholder,
          tooltip: options.tooltip,
          readOnly: schema?.readOnly,
        };
        if (options.displayKeyOnSelect && !dropdownOpened) {
          params.inputProps = { ...params.inputProps, value };
          return <GenericInput {...inputProps} />;
        }

        return <GenericInput {...inputProps} />;
      }}
    />
  );
};

export default AutocompleteInput;
