import React, { ReactElement, useEffect, useState } from 'react';
import useNavigationSteps from '../../checkout3/common/hooks/useNavigationSteps';
import { dxpPriceDisplay } from '../../../common/service/dxpPriceDisplay';
import usePlatformConfig from '../../../common/react/usePlatformConfig';
import useCheckoutContext from '../hooks/useCheckoutContext';
import useWidgetContext from '../../../common/react/useWidgetContext';
import useCallDxpDigitalAssignmentEntryEvent from '../hooks/useDxpDigitalAssignmentEntryEvent';

const SeoHelmet = (): ReactElement => {
  const [dxpDigitalDataAssignmentCalled, setDxpDigitalDataAssignmentCalled] = useState(false);
  const { steps, activeStep } = useNavigationSteps();
  const { configuration } = useWidgetContext();
  const { callDxpDigitalAssignmentEntryEvent } = useCallDxpDigitalAssignmentEntryEvent();

  const {
    platformConfig: { dxpDataLayer, priceDisplay },
  } = usePlatformConfig();
  const { deal, loading } = useCheckoutContext();

  useEffect(() => {
    if (dxpDataLayer && !loading && !dxpDigitalDataAssignmentCalled && deal) {
      dxpPriceDisplay.set(configuration.dimensions, priceDisplay);
      callDxpDigitalAssignmentEntryEvent(deal, steps, activeStep.index);
      setDxpDigitalDataAssignmentCalled(true);
    }
  }, [loading, dxpDataLayer, deal]);

  return <></>;
};

export default SeoHelmet;
