import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import enquiryForm from '../graphql/queries/enquiryForm';
import {
  GetEnquiryFormSchema,
  GetEnquiryFormSchemaVariables,
} from '../graphql/queries/__generated__/GetEnquiryFormSchema';
import { IEnquiryForm, IEnquiryFormSchema } from '../model/IJsonSchema';
import { customEvent } from '../../../../common/service/customEvent';
import { MyAccountEvents } from '../../../../common/enum/MyAccountEvents';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { useEnquiryDataLayer } from './useEnquiryDataLayer';

export const useEnquireFormData: any = (token: string, state?: string) => {
  const { data, loading, error, refetch } = useQuery<GetEnquiryFormSchema, GetEnquiryFormSchemaVariables>(
    enquiryForm.GET_ENQUIRY_FORM_SCHEMA,
    {
      variables: {
        token,
        state,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const { deal } = useCheckoutContext();
  
  const {
    platformConfig: {
      dxpDataLayer,
      platformFeatures: { retainedFormFieldsOnRefresh },
    },
  } = usePlatformConfig();
  
  const [formData, setFormData] = useState<IEnquiryForm | IEnquiryFormSchema | null>(null);
  const [formSchema, setFormSchema] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const { handleDataLayerChange } = useEnquiryDataLayer(deal)

  const handleFormChange = useCallback(
    (formData: IEnquiryFormSchema) => {
      if (dxpDataLayer) {
        handleDataLayerChange({ formData: formData as IEnquiryFormSchema, formSchema });
      }
      setFormData(formData);
    },
    [formData],
  );

  const setRequiredFieldsOnLoad = useCallback((formSchema: any) => {
    if (!formSchema) {
      return;
    }
    let required: string[] = formSchema.required || [];

    Object.values(formSchema.properties).forEach((item: any) => (required = required.concat(item?.required || [])));
    const requiredUpdated = required.map(el => (el === 'userIdType' ? 'identificationType' : el));
    setRequiredFields(requiredUpdated);
  }, []);

  const hasExistingFormDataValue = (key: string, formDataExistingValue?: string) =>
    formDataExistingValue && retainedFormFieldsOnRefresh?.includes(key);

  useEffect(() => {
    if (!loading && data?.enquiryForm) {
      const replaceNullValues = data.enquiryForm.formData.replace(/\:null/gi, ':""');
      const newJSONFormData = JSON.parse(replaceNullValues);
      const parsedSchema = JSON.parse(data.enquiryForm.schema);
      const { properties: schemaProperties } = parsedSchema;

      // set default value if the field has one
      for (const [key, value] of Object.entries<any>(schemaProperties)) {
        if ('properties' in value) {
          for (const [propKey, { default: defaultValue }] of Object.entries<any>(value.properties)) {
            const formSection = (formData as any)?.[key];
            const formDataExistingValue = formSection && formSection[propKey];

            if (hasExistingFormDataValue(propKey, formDataExistingValue)) {
              newJSONFormData[key] = {
                ...newJSONFormData?.[key],
                [propKey]: formDataExistingValue,
              };
            } else if (newJSONFormData[key]?.[propKey] === '' && defaultValue != null) {
              newJSONFormData[key][propKey] = defaultValue;
            }
          }
        } else {
          const formDataExistingValue = (formData as any)?.[key];

          if (hasExistingFormDataValue(key, formDataExistingValue)) {
            newJSONFormData[key] = formDataExistingValue;
          } else if (newJSONFormData[key] === '' && schemaProperties[key].default != null) {
            newJSONFormData[key] = schemaProperties[key].default;
          }
        }
      }
      setFormSchema(parsedSchema);
      setUiSchema(JSON.parse(data.enquiryForm.uiSchema));
      setFormData(newJSONFormData);
      setRequiredFieldsOnLoad(parsedSchema);
    }
  }, [loading]);

  const receiveMyAccountResponse = useCallback(({ detail }) => {
    if (detail) {
      refetch();
    }
  }, []);

  useEffect(() => {
    customEvent.listen(MyAccountEvents.ON_LOGIN_SUCCESS, receiveMyAccountResponse);
    return () => {
      customEvent.removeListener(MyAccountEvents.ON_LOGIN_SUCCESS, receiveMyAccountResponse);
    };
  }, []);

  return { formData, handleFormChange, formSchema, uiSchema, requiredFields, error, refetch };
};
