import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { UrlParamTypes } from '../../../../../../common/enum/UrlParamTypes';
import mercadoPagoContainerStyles from './mercadoPagoContainerStyles';
import { PAYMENT_CONFIG } from '../../../../../../common/constants/payment';

export interface IMercadoPagoProps {
  token: string;
}

const MercadoPagoContainer: FunctionComponent<IMercadoPagoProps> = ({ token }) => {
  const styles = mercadoPagoContainerStyles();

  const getIframeUrl = () => {
    return `${PAYMENT_CONFIG.dxpWidgetUrl}?${UrlParamTypes.TOKEN}=${token}`;
  };

  return (
    <Box data-test="payment:mercadopago:iframe">
      <iframe src={getIframeUrl()} className={styles.container} />
    </Box>
  );
};

export default MercadoPagoContainer;
