
import IDimensions from '../model/IDimensions';
import { IPriceOverride } from '../model/IPrice';
import { IPriceDisplay } from '../model/IPriceDisplay';

function buildId(dimension: IDimensions): string {
  return `${dimension.country}_${dimension.brand}_priceDisplay`;
}

export const dataLayerPriceDisplay = {
  get: (dimension: IDimensions) => {
    return localStorage.getItem(buildId(dimension)) ?? 'null';
  },
  set: (dimension: IDimensions, value: IPriceDisplay) => {
    localStorage.setItem(buildId(dimension), JSON.stringify(value));
  },
};

export const priceExtractionHandler = (prices: IPriceOverride[], dimension: any) => {
  const priceDisplay = JSON.parse(dataLayerPriceDisplay.get(dimension)) || null;
  const defaultPrice = {
    money: {
      amount: 0,
      currency: '',
    },
    type: '',
  };

  let isPrimaryPrice: (price: IPriceOverride) => boolean;
  let isSecondaryPrice: (price: IPriceOverride) => boolean;

  if (priceDisplay) {
    const isDisplayPriceByType = priceDisplay.displayPriceByType;

    if (isDisplayPriceByType) {
      isPrimaryPrice = price => price.type === priceDisplay.primary.priceType;
      isSecondaryPrice = price => price.type === priceDisplay?.secondary?.priceType;
    } else {
      isPrimaryPrice = price => price.money.currency === priceDisplay.primary.currency;
      isSecondaryPrice = price => price.money.currency === priceDisplay?.secondary?.currency;
    }

    const getPrimaryPrice = (prices: IPriceOverride[]) => prices?.find(price => isPrimaryPrice(price));
    const getSecondaryPrice = (prices: IPriceOverride[]) => prices?.find(price => isSecondaryPrice(price));

    const primaryPrice = getPrimaryPrice(prices) ?? defaultPrice;
    const secondaryPrice = getSecondaryPrice(prices) ?? defaultPrice;

    return { primaryPrice, secondaryPrice };
  }
};