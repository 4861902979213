import React, { FunctionComponent, useState } from 'react';
import errorHandler from '../../../../common/service/errorHandler';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import errorMessageHoc, {
  IErrorMessageHocProps,
} from '../../../../common/components/presentation/Error/errorMessageHoc';
import WebpayContainer from './paymentProviders/webpay/WebpayContainer';
import Payment2C2PContainer from './paymentProviders/2c2p/Payment2C2PContainer';
import PaymentVisaNet from './paymentProviders/visanet/PaymentVisaNet';
import PaymentEwayContainer from './paymentProviders/eway/PaymentEwayContainer';
import PAYMENT_TYPE from '../../../common/constants/paymentType';
import { GetDeal_deal } from '../../../common/graphql/queries/__generated__/GetDeal';
import MercadoPagoContainer from './paymentProviders/mercadopago/MercadoPagoContainer';

export interface IPayment extends IErrorMessageHocProps {
  goToNextStep: () => void;
  deal: GetDeal_deal;
  loading?: boolean;
}

const Payment: FunctionComponent<IPayment> = ({ goToNextStep, deal, loading }) => {
  const [parentNodeId] = useState<string>('wrapper:checkout:paymentProvider');
  const {
    platformConfig: { paymentProvider },
  } = usePlatformConfig();

  const paymentProviderMap = {
    [PAYMENT_TYPE.WEBPAY]: <WebpayContainer deal={deal} />,
    [PAYMENT_TYPE['2C2P']]: <Payment2C2PContainer goToNextStep={goToNextStep} deal={deal} loading={loading} />,
    [PAYMENT_TYPE.VISANET]: <PaymentVisaNet deal={deal} />,
    [PAYMENT_TYPE.EWAY]: <PaymentEwayContainer />,
    [PAYMENT_TYPE.MERCADOPAGO]: <MercadoPagoContainer token={deal?.uuid} />,
  };

  const selectedProvider = paymentProviderMap[paymentProvider];

  if (!selectedProvider) {
    errorHandler.handleError(`No payment provider was selected. [${parentNodeId}]`);
    return <div data-test="payment_page:no_payment_provider">There is no payment provider</div>;
  }

  return selectedProvider;
};

export default errorMessageHoc(Payment);
