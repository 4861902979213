const PAYMENT_TYPE = {
  WEBPAY: 'webpay',
  APEXX: 'apexx',
  VISANET: 'visanet',
  '2C2P': '2C2P',
  EWAY: 'eway',
  MERCADOPAGO: 'mercadopago',
};

export default PAYMENT_TYPE;
