import { IEnquiryFormData, IEnquiryFormSchema } from '../model/IJsonSchema';
import { DxpDataLayerEvent, DxpDataLayerEventNames } from '../../../../../../common/utilities/customDatalayerInterface';
import dxpProducts from '../../../../../../common/constants/dxpProducts';
import { formatDataLayerDeal, getSaveEnquiryFormNextSteps } from '../../../common/utils/dxpDataLayerHandler';
import { JourneyStepsRoutes } from '../../../checkout4/enum/JourneyStepsRoutes';
import dxpDigitalDataAssignment from '../../../../../../common/utilities/dxpDigitalDataAssignment';
import { GetDeal_deal } from '../../../common/graphql/queries/__generated__/GetDeal';

type TChangeHandler = {
  formData: IEnquiryFormSchema | IEnquiryFormData | null;
  formSchema: {
    properties: {
      [propName: string]: {
        properties: {
          dealerSelect: TDealerSelect;
        };
      } & TDealerSelect;
    };
  };
};

type TUpdateDataLayer = {
  dealerSelect: TDealerSelect;
  dealerIndex: string | number;
};

type TDealerSelect = {
  enum: number[];
  enumNames: string[];
};

export const useEnquiryDataLayer = (deal: GetDeal_deal) => {
  const handleDataLayerChange = ({ formSchema, formData }: TChangeHandler) => {
    let dealerSelect: null | TDealerSelect = null;
    let dealerIndex = null;
    const formDataSchema = formData as IEnquiryFormSchema;
    const formDataData = formData as IEnquiryFormData;

    const getDealerSelect = (property: string) => {
      return formSchema?.properties?.[property]?.properties?.dealerSelect;
    };

    if (formDataSchema?.dealerConsultant?.dealerSelect) {
      dealerSelect = getDealerSelect('dealerConsultant');
      dealerIndex = formDataSchema?.dealerConsultant?.dealerSelect;
    } else if (formDataSchema?.appointment?.dealerSelect) {
      dealerSelect = getDealerSelect('appointment');
      dealerIndex = formDataSchema?.appointment?.dealerSelect;
    } else if (formDataSchema?.cityDealer?.dealerSelect) {
      dealerSelect = getDealerSelect('cityDealer');
      dealerIndex = formDataSchema?.cityDealer?.dealerSelect;
    } else if (formDataData?.dealerSelect) {
      dealerSelect = formSchema?.properties.dealerSelect;
      dealerIndex = formDataData?.dealerSelect;
    } else if (formDataSchema?.cityDistrictDealer) {
      dealerSelect = getDealerSelect('cityDistrictDealer');
      dealerIndex = formDataSchema?.cityDistrictDealer?.dealerSelect;
    }

    if (dealerSelect && dealerIndex) {
      updateDealerSelectDataLayer({ dealerSelect, dealerIndex });
    }
  };

  const updateDealerSelectDataLayer = ({ dealerSelect, dealerIndex }: TUpdateDataLayer) => {
    const dealerEnum = dealerSelect?.enum.findIndex((e: string | number) => e === dealerIndex);
    const dealerName = dealerSelect?.enumNames[dealerEnum];
    const dataLayer = {
      event: DxpDataLayerEvent.CONFIGURATOR,
      eventName: DxpDataLayerEventNames.CNF_DEALERS_CLICKED,
      pageTitle: document.title,
      DXPProduct: dxpProducts.checkout,
      dealerPreference: dealerName,
      ...formatDataLayerDeal(deal),
      nextSteps: getSaveEnquiryFormNextSteps(),
      stepName: JourneyStepsRoutes.DEALER.replace('/', ''),
    };
    dxpDigitalDataAssignment(dataLayer);
  };

  return { handleDataLayerChange, updateDealerSelectDataLayer };
};
