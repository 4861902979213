import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  IAsyncValidationError,
  IEnquiryForm,
  IEnquiryFormData,
  IEnquiryFormSchema,
  IAsyncError,
} from '../model/IJsonSchema';
import enquiryFormDataTransformerGQL from '../service/enquiryFormDataTransformerGQL';
import enquiryFormErrorTransformer from '../service/enquiryFormErrorTransformer';
import { ApolloError } from '@apollo/client';
import { CustomerTypes } from '../../../../common/enum/CustomerTypes';
import { constants } from '../../../../../../common/constants/constants';

interface IValidationReturn {
  shouldValidate?: boolean;
  hasErrors: boolean;
  asyncErrors: IAsyncValidationError | null;
  handleValidationErrors: (data: IEnquiryForm) => void;
  handleAsyncValidationErrors: (error: ApolloError) => void;
  resetAsyncErrors: () => void;
  checkEmptyValues: (data: IEnquiryFormSchema) => boolean;
  customValidation: (data: IEnquiryFormSchema, error: IAsyncError) => IAsyncError;
  setHasErrors: Dispatch<SetStateAction<boolean>>;
}

const useEnquiryFormValidate: (requiredFields: keyof IEnquiryFormData) => IValidationReturn = requiredFields => {
  const intl = useIntl();

  const [hasErrors, setHasErrors] = useState<boolean>(true);
  const [asyncErrors, setAsyncErrors] = useState<IAsyncValidationError | null>(null);
  const checkEmptyValues = useCallback(
    (data: IEnquiryFormSchema) => {
      const formattedData: IEnquiryFormData = enquiryFormDataTransformerGQL.from(data);

      const requiredFormattedData: any = Object.keys(formattedData).reduce((acc: any, key) => {
        if (requiredFields.includes(key)) {
          acc = [...acc, formattedData[key as keyof IEnquiryFormData]];
        }
        return acc;
      }, []);

      const hasEmpty =
        requiredFormattedData.some((val: string | boolean) => {
          if (typeof val === 'string') {
            return !val.trim();
          }
          return !val;
        }) || requiredFormattedData.length !== requiredFields.length;

      if (hasEmpty) {
        setHasErrors(hasEmpty);
      }

      return hasEmpty;
    },
    [requiredFields],
  );

  const handleValidationErrors = useCallback(
    (data: IEnquiryForm) => {
      const hasValidationErrors = data.errors?.length > 0;

      setHasErrors(hasValidationErrors || checkEmptyValues(data.formData));
    },
    [hasErrors, asyncErrors, requiredFields],
  );

  const handleAsyncValidationErrors = useCallback(
    ({ errors }) => {
      const err: IAsyncValidationError = enquiryFormErrorTransformer.from(errors, intl);
      setAsyncErrors(err);
    },
    [asyncErrors],
  );

  const resetAsyncErrors = useCallback(() => {
    setAsyncErrors(null);
  }, []);

  const customValidation = useCallback((data: IEnquiryFormSchema, error: IAsyncError): IAsyncError => {
    if (data?.tradeIn) {
      const {
        tradeIn: { tradeInCheckbox, make, model, variant, year, mileage, colour },
      } = data;
      if (tradeInCheckbox && (!make || !model || !variant || !year || !mileage || !colour)) {
        setHasErrors(true);
      }
    }

    if (data?.customerTypeSwitch) {
      const { companyName, customerType } = data?.customerTypeSwitch;

      if (customerType === CustomerTypes.COMPANY && !companyName) {
        setHasErrors(true);
      }
    }

    if (data.termsAndConditionsWithConsent && data.termsAndConditionsWithConsent === constants.NO) {
      setHasErrors(true);
    }

    return error;
  }, []);

  return {
    hasErrors,
    asyncErrors,
    handleValidationErrors,
    handleAsyncValidationErrors,
    resetAsyncErrors,
    checkEmptyValues,
    customValidation,
    setHasErrors,
  };
};

export default useEnquiryFormValidate;
